import axios from 'axios';
import { decodeJWT } from './jwt';
import router from '@/router';
import { useAuthStore } from '@/store/global/auth';

// Setting up the base URL based on the environment
const hostname = window.location.hostname;
const environments = {
	development: ['localhost', '127.0.0.1'],
	staging: 'staging.semactic.com',
	testing: 'testing.semactic.com',
	production: 'django.semactic.com',
};

let baseURL = 'https://django.semactic.com/api/';

if (environments.development.includes(hostname)) {
	baseURL = 'http://127.0.0.1:8000/api/';
} else if (hostname.includes(environments.staging)) {
	baseURL = 'https://django-staging.semactic.com/api/';
} else if (hostname.includes(environments.testing)) {
	baseURL = 'https://django-testing.semactic.com/api/';
}

axios.defaults.baseURL = baseURL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

const getProjectId = () => {
	const project = JSON.parse(localStorage.getItem('market'));
	if (!project) {
		return null;
	}
	return project.selectedProject;
};

// Function to update the user role in localStorage and reload if it has changed
const updateRole = role => {
	const user = JSON.parse(localStorage.getItem('auth'));

	if (user && user.role !== role) {
		user.role = role;
		user.access = getRoleName(role);
		localStorage.setItem('auth', JSON.stringify(user));
		location.reload();
	}
};

// Function to map role IDs to role names
const getRoleName = role => {
	switch (role) {
		case 262144:
			return 'super_admin';
		case 1:
			return 'admin';
		case 8192:
			return 'project manager';
		case 32768:
			return 'digital specialist';
		case 65536:
			return 'digital enabler';
		case 2048:
			return 'partner';
		default:
			return 'guest';
	}
};

const sendRefresh = async () => {
	const response = await axios.post(
		'refresh',
		{ project_id: getProjectId() },
		{ withCredentials: true }
	);

	if (response.status === 200) {
		const { token, role } = response.data;
		const authStore = useAuthStore();
		
		// Update token in auth store instead of localStorage
		authStore.setDecodedToken(token);
		authStore.updateRole(role);

		return token;
	}

	return null;
}

axios.interceptors.request.use(
	async (config) => {
		// Avoid the refresh request from being intercepted by itself
		const unprotectedEndpoints = ['refresh', 'login'];
		if (unprotectedEndpoints.includes(config.url)) {
			return config;
		}

		const authStore = useAuthStore();
		const { decodedToken } = storeToRefs(authStore);

		// Add this debug log
		console.log('Current token:', decodedToken.value?.token);
		console.log('Current headers:', config.headers);

		if (decodedToken.value?.token) {
			// Always set the Authorization header if we have a token
			config.headers['Authorization'] = `Bearer ${decodedToken.value.token}`;
			axios.defaults.headers.common['Authorization'] = `Bearer ${decodedToken.value.token}`;

			// Check if token is expired
			const currentTime = Math.floor(Date.now() / 1000);
			if (decodedToken.value.exp < currentTime) {
				console.log('Token expired');
				if (!isRefreshing) {
					isRefreshing = true;
					try {
						const newToken = await sendRefresh();
						if (newToken) {
							config.headers['Authorization'] = `Bearer ${newToken}`;
							axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
						}
						isRefreshing = false;
					} catch (error) {
						console.log('Error refreshing token:', error);
						isRefreshing = false;
					}
				}
			}
		} else {
			// If no token exists, try to refresh
			if (!isRefreshing) {
				isRefreshing = true;
				try {
					const newToken = await sendRefresh();
					if (newToken) {
						config.headers['Authorization'] = `Bearer ${newToken}`;
						axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
					}
				} catch (error) {
					console.log('Error refreshing token:', error);
				}
				isRefreshing = false;
			}
		}

		return config;
	},
	(error) => {
		console.log('Error during request setup:', error);
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => response,
	async error => {
		const originalRequest = error.config;

		if (error.response && error.response.status === 401) {
			const currentRoute = router.currentRoute.value;
			const isAuth = currentRoute.meta?.isAuth;

			if (error.response.config.url === 'refresh' && error.response.data.detail === 'unauthenticated') {

				if (isAuth) {
					window.location.href = '/logout';
				}
				return Promise.reject(error);
			}

			if (!isRefreshing) {
				isRefreshing = true;
				return new Promise(function (resolve, reject) {
					axios.post('refresh', 
						{ project_id: getProjectId() },
						{ withCredentials: true }
					)
					.then(({ status, data }) => {
						if (status === 200) {
							const authStore = useAuthStore();
							authStore.setDecodedToken(data.token);
							authStore.updateRole(data.role);

							axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
							originalRequest.headers['Authorization'] = `Bearer ${data.token}`;

							processQueue(null, data.token);
							resolve(axios(originalRequest));
						}
					})
					.catch(err => {
						processQueue(err, null);
						window.location.href = '/logout';
						reject(err);
					})
					.then(() => {
						isRefreshing = false;
					});
				});
			} else {
				return new Promise(function (resolve, reject) {
					failedQueue.push({ resolve, reject });
				})
					.then(token => {
						originalRequest.headers['Authorization'] = 'Bearer ' + token;
						return axios(originalRequest);
					})
					.catch(err => Promise.reject(err));
			}
		}

		return Promise.reject(error);
	},
);